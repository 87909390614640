import { gql } from "@apollo/client";

const GET_TOKEN_FROM_REFRESH_TOKEN = gql`
query RefreshSessionToken($refreshToken: String) {
  refreshSessionToken(refreshToken: $refreshToken) {
    token
    tokenTime
  }
}`;

const GET_USERS = gql`
query Query( $userId: String, $orgId: String,$name: String,$email: String,$phoneNum: String,$role: String,$limit: Int, $page: Int) {
  usersWithRolesByOrganizationId( userId: $userId,orgId: $orgId,name: $name,email: $email,phoneNum: $phoneNum,role: $role, limit: $limit, page: $page) {
    totalPages
    totalDocs
    limit
    currentPage
    users{
          _id
          name
          email
          gender
          phone
          organization
          roles {
            _id
            roleCode
            roleName
            createdAt
          }
      }
   }
}`;

const GET_USER_BY_ID = gql`
query UserWithRolesById($userWithRolesByIdId: String) {
  userWithRolesById(id: $userWithRolesByIdId) {
    name
    email
    gender
    phone
    organization
    roles {
      _id
      roleCode
      roleName
      createdAt
    }
  }
}`;

const GET_USER_ANALYTICS = gql`
query AnalyticsByUserId($analyticsByUserIdId: String) {
  analyticsByUserId(id: $analyticsByUserIdId) {
    attempts {
      user
      uniqueId
      accuracy
      stats {
        stepNo
        stepName
        result
        totalTimeAlloted
        timeTaken
        skipped
      }
    }
    name
    grade{
      _id
      userId
      grade
      uniqueId
    }
  }
}`;

const GET_USER_ANALYTICS_BY_USERID_TRAININGID = gql`
query AnalyticsByUserIdTrainingIdStepNo($userId: String, $trainingId: String, $stepNo: Int) {
  analyticsByUserIdTrainingIdStepNo(userId: $userId, trainingId: $trainingId, stepNo: $stepNo) {
    attempts {
      accuracy
      stats {
        skipped
        stepName
        stepNo
        timeTaken
        totalTimeAlloted
        result
      }
      training
      uniqueId
      user
      createdAt
    }
  }
}`;

const GET_QUESTION_DETAILS = gql`
query GetQuestionDetails($userId: String!, $moduleName: String!) {
  getQuestionDetails(userId: $userId, moduleName: $moduleName) {
    scenarioId
    scenarioName
    attempts {
      timestamp
      timeTaken
      passed
    }
  }
}`;

const GET_TRAINING_MODULE = gql`
query Query($page: Int, $limit: Int,$search: SearchInputPackage) {
  getAllTrainingPackages(page: $page, limit: $limit,search: $search) {
    totalPages
    totalDocs
    limit
    currentPage
    trainingPackages {
      _id
      name
      trainings {
        _id
        name
      }
    }
  }
}`;

const GET_TRAINING_MODULE__BY_ID = gql`
query Query($trainingPackageByIdId: String) {
  trainingPackageById(id: $trainingPackageByIdId) {
    _id
    name
    trainings {
      _id
      name
      typeOfTraining
      difficultyLevel
      description
    }
  }
}`;

const GET_ALL_TRAININGS = gql`
query Query($page: Int, $limit: Int, $search: SearchInput) {
  getAllTrainings(page: $page, limit: $limit, search: $search) {
    totalPages
    totalDocs
    limit
    currentPage
    trainings {
      _id
      name
      description
      difficultyLevel
      typeOfTraining
    }
  }
}`;


const GET_TRAINING_BY_ID = gql`
query TrainingById($trainingByIdId: String) {
  trainingById(id: $trainingByIdId) {
    _id
    description
    difficultyLevel
    name
    typeOfTraining
    trainingSteps {
      _id
      audioText
      isActive
      stepName
    }
  }
}`;


const GET_ALL_ORGANIZATIONS = gql`
query AllOrganizations($page: Int, $limit: Int, $search: OrganizationSearchInput) {
  allOrganizations(page: $page, limit: $limit, search: $search) {
    totalPages
    totalDocs
    limit
    currentPage
    organizations {
      name
      image
      address
      city
      country
      zip
      phone
      alternatePhone
      trainingPackage {
        _id
        name
      }
      userLicenseCount
      isActive
      _id
      allotedHardwares {
        _id
        organization
        serialNumber
      }
    }
  }
}`;

const GET_ORG_BY_ID = gql`
query OrganizationById($organizationByIdId: String) {
  organizationById(id: $organizationByIdId) {
    name
    orgAbbreviation
    image
    address
    city
    country
    zip
    phone
    adminName
		adminEmail
    alternateEmail
    alternatePhone
    trainingPackage {
      _id
      name
    }
    training {
      _id
      name
    }
    userLicenseCount
    isActive
    allotedHardwares {
      _id
      organization
      serialNumber
      deviceId
      deviceModel     
      source           
      warranty        
      purchaseDate    
      invoice          
      deviceImage      
    }
    users { 
      name
      email
      gender
      phone
      organization
        roles {
        _id
        roleCode
        roleName
        createdAt
      }
    }
    _id
  }
}`;

const GET_LEADERBOARD_DATA = gql`
query Query($orgId: String) {
  leaderBoard(orgId: $orgId) {
    name
    totalTrainings
    rank
    trainingCompleted
    accuracy
  }
}
`;


const GET_ALL_COHORTS = gql`
query GetAllCohorts( $search: CohortSearchParams) {
  getAllCohorts( search: $search) {
    _id
    cohortModule
    cohortName
    cohortStartDate
    cohortTrainings
    userIds
    isActive
  }
}`;

const GET_COHORT_BY_ID = gql`
query GetCohortById($getCohortByIdId: String) {
  getCohortById(id: $getCohortByIdId) {
    _id
    cohortModule
    cohortName
    cohortStartDate
    cohortTrainings
    userIds
    orgId
    isActive
  }
}`;



export {
  GET_USERS,
  GET_USER_BY_ID,
  GET_USER_ANALYTICS,
  GET_USER_ANALYTICS_BY_USERID_TRAININGID,
  GET_QUESTION_DETAILS,
  GET_TRAINING_MODULE,
  GET_ALL_ORGANIZATIONS,
  GET_ORG_BY_ID,
  GET_TOKEN_FROM_REFRESH_TOKEN,
  GET_ALL_TRAININGS,
  GET_LEADERBOARD_DATA,
  GET_TRAINING_BY_ID,
  GET_TRAINING_MODULE__BY_ID,
  GET_ALL_COHORTS,
  GET_COHORT_BY_ID
};