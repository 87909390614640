import { useQuery, useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useSelector } from "react-redux"; // Or your state management library
import { Link } from "react-router-dom";
import Card from "../../Components/Cards/Card";
import DataTable from "../../Components/Datatable";
import Message from "../../Components/Message";
import Stats from "../../Components/Stats";
import TableHeading from "../../Components/TableHeading";
import globals from "../../Constants/Globals";
import { GET_ALL_TRAININGS, GET_USER_BY_ID, GET_ORG_BY_ID, GET_TRAINING_MODULE__BY_ID } from "../../Graphql/Queries";
import { SuperAdminPaths } from "../../Routes/Paths";
import { pages } from "../../Types/DatatableType";
import defaultImage from '../../assets/defaultImage.svg';  // Corrected import path
import { DELETE_TRAINING } from "../../Graphql/Mutations";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Error } from '../../Types/MessageType';

type TrainingsProps = {
  showCreateTrainings?: boolean;
  check?: boolean;
  showViewDetails?: boolean;
  formdata?: string[];
  tableFormItemName?: string;
  setUserIdArray?: (val: any[]) => void;
 };
 const Trainings = ({ showCreateTrainings = true, check = false, formdata, tableFormItemName, showViewDetails = true, setUserIdArray }: TrainingsProps) => {


  const { CREATE_TRAINING } = SuperAdminPaths;

  const [tableData, setTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<pages>();
  const [alert, setAlert] = useState<string>('');
  const [alertMsg, setAlertMsg] = useState<Error>({ type: 'warning', message: '' });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isRootAdmin, setIsRootAdmin] = useState<boolean>(false);
  const [trainingPackageId, setTrainingPackageId] = useState<string | null>(null);

  const userId = useSelector((state: any) => state.auth.userId);
  const [searchCriteria, setSearchCriteria] = useState({
    name: '',
    typeOfTraining: '',
    difficultyLevel: ''
  });

  const [deleteTraining] = useMutation(DELETE_TRAINING);
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState(null);

  const handleSearchChange = (field: string, value: string) => {
    setSearchCriteria(prev => ({
      ...prev,
      [field]: value
    }));
  };
  const { data: userData, loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: { userWithRolesByIdId: userId },
    onCompleted(data) {
      const roles = data?.userWithRolesById?.roles || [];
      const isAdmin = roles.some((role: any) => role.roleCode === "ROOT_ADMIN");
      setIsRootAdmin(isAdmin);
      const orgId = data?.userWithRolesById?.organization || null;
      if (!isAdmin && orgId) {
        setTrainingPackageId(orgId); 
      }
    },
    onError(err) {
      console.error("Error fetching user data:", err.message);
    }
  });

  // Fetch organization details to get trainingPackageId if not a root admin
  useQuery(GET_ORG_BY_ID, {
    skip: isRootAdmin || !trainingPackageId,
    variables: { organizationByIdId: trainingPackageId },
    onCompleted(data) {
      const trainingPackage = data?.organizationById?.trainingPackage?._id || null;
      setTrainingPackageId(trainingPackage);
    },
    onError(err) {
      console.error("Error fetching organization data:", err.message);
    }
  });

  // Query for root admin
  const { loading: trainingLoading } = useQuery(GET_ALL_TRAININGS, {
    skip: !isRootAdmin,
    variables: {
      page: currentPage,
      limit: globals.pageSize,
      search: {
        name: searchCriteria.name,
        typeOfTraining: searchCriteria.typeOfTraining,
        difficultyLevel: searchCriteria.difficultyLevel
      }
    },
    onCompleted(data) {
      if (data.getAllTrainings.trainings.length > 0) {
        const training = data?.getAllTrainings?.trainings;
        setTotalPages({
          currentPage: data.getAllTrainings?.currentPage,
          limit: globals.pageSize,
          totalDocs: data.getAllTrainings?.totalDocs,
          totalPages: data.getAllTrainings?.totalPages,
        });
        setTableData([]);
        if (training.length > 0) {
          const _training = training.map((_singleT: any, index: number) => {
            return {
              key: index,
              _id: _singleT._id,
              name: _singleT.name,
              description: _singleT.description,
              difficultyLevel: _singleT.difficultyLevel,
              typeOfTraining: _singleT.typeOfTraining,
              thumbnail: _singleT.thumbnail || defaultImage, // Use default image if no thumbnail
            };
          });
          setTableData(_training);
        }
      }
    },
    onError(err) {
      console.log("Error fetching all trainings:", err.message);
      setAlert(err.message);
    },
  });

  // Query for non-root admin
  const { loading: trainingPackageLoading } = useQuery(GET_TRAINING_MODULE__BY_ID, {
    skip: isRootAdmin || !trainingPackageId,
    variables: { trainingPackageByIdId: trainingPackageId },
    onCompleted(data) {
      const trainingPackage = data?.trainingPackageById?.trainings || [];
      setTotalPages({
        currentPage,
        limit: globals.pageSize,
        totalDocs: trainingPackage.length,
        totalPages: Math.ceil(trainingPackage.length / globals.pageSize),
      });
      const _training = trainingPackage.map((_singleT: any, index: number) => {
        return {
          key: index,
          _id: _singleT._id,
          name: _singleT.name,
          description: _singleT.description,
          difficultyLevel: _singleT.difficultyLevel,
          typeOfTraining: _singleT.typeOfTraining,
          thumbnail: _singleT.thumbnail || defaultImage, // Use default image if no thumbnail
        };
      });
      setTableData(_training);
    },
    onError(err) {
      console.log("Error fetching training package:", err.message);
      setAlert(err.message);
    },
  });

  const handleClickOpen = (record: any) => {
    setRecord(record);
    setOpen(true);
  };

  const handleDelete = () => {
    handleDeleteTraining(record);
    setOpen(false);
    setRecord(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteTraining = (record: any): void => {
    deleteTraining({
      variables: {
        id: record._id
      },
      onCompleted(data) {
        const updatedTableData = tableData.filter(item => item._id !== record._id);
        setTableData(updatedTableData);
        setAlertMsg({ type: "success", message: "Training deleted successfully" });
        setTimeout(() => {
          setAlertMsg({ type: 'success', message: '' });
        }, globals.timeout);
      },
      onError(error) {
        setAlertMsg({ type: 'error', message: error.message });
        setTimeout(() => {
          setAlertMsg({ type: 'error', message: '' });
        }, globals.timeout);
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <TableHeading heading="Thumbnail"  setPage={setCurrentPage} />
      ),
      dataIndex: "thumbnail",
      render: (text) => (
        <img src={text} alt="Thumbnail" style={{ width: "60px", height: "auto", display: "block", margin: "auto", marginTop: "-15px", marginBottom: "-15px" }} />
      ),
      key: "1",
      width: "20%",
    },
    {
      title: (
        <TableHeading heading="Training Name" setSearch={(value) => handleSearchChange('name', value)}  setPage={setCurrentPage} />
      ),
      dataIndex: "name",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "2",
      width: "20%",
    },
    {
      title: (
        <TableHeading heading="Type Of Training" setSearch={(value) => handleSearchChange('typeOfTraining', value)}  setPage={setCurrentPage} />
      ),
      dataIndex: "typeOfTraining",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "3",
      width: "20%",
    },
    {
      title: (
        <TableHeading heading="Difficulty Level" setSearch={(value) => handleSearchChange('difficultyLevel', value)} setPage={setCurrentPage} />
      ),
      dataIndex: "difficultyLevel",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "4",
      width: "20%",
    },
    {
      title: '',
      render: (_, record) => showViewDetails && <Button variant="contained" sx={{ alignSelf: 'flex-end' }}><Link style={{ textDecoration: 'none', color: 'white' }} to={record._id} state={{ trainingId: record._id }}>View Details</Link></Button>,
      key: "5",
      width: "20%",
    },
    {
      title: '',
      render: (_, record) => (
        <IconButton
          sx={{ alignSelf: 'flex-end' }}
          onClick={() => handleClickOpen(record)}
        >
          <DeleteIcon />
        </IconButton>
      ),
      key: "6",
      width: "5%"
    },
  ];

  const loading = userLoading || (isRootAdmin ? trainingLoading : trainingPackageLoading);

  return (
    <>
      {
      showCreateTrainings && <Stats buttonText="Create Training" text="Total Trainings" number={tableData.length} link={CREATE_TRAINING} />
      }
      <Card>
        {
          !!alert ? (
            <Message type="warning" message={alert} />
          ) : (
            <DataTable
              loading={trainingLoading}
              tableData={tableData}
              totalPages={totalPages}
              showCheck={check}
              columnData={columns}
              page={(cp) => {
                setCurrentPage(Number(cp));
              }}
              scrollX="max-content"
              formData={formdata}
              tableFormItemName={tableFormItemName}
              scrollY={450}
            />
          )
        }
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this training?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Trainings;