import { useQuery, useMutation } from "@apollo/client";
import { Button, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, IconButton } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../Components/Cards/Card";
import DataTable from "../../Components/Datatable";
import Stats from "../../Components/Stats";
import TableHeading from "../../Components/TableHeading";
import globals from "../../Constants/Globals";
import { GET_ALL_COHORTS, GET_USER_BY_ID, GET_COHORT_BY_ID } from "../../Graphql/Queries";
import { DELETE_COHORT } from "../../Graphql/Mutations";
import { AdminPaths } from "../../Routes/Paths";
import { pages } from "../../Types/DatatableType";
import DeleteIcon from '@mui/icons-material/Delete';
import Message from '../../Components/Message';
import { Error } from '../../Types/MessageType';

const Cohort = () => {
  const { CREATE_COHORT_PATH } = AdminPaths;

  const [tableData, setTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<pages>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isRootAdmin, setIsRootAdmin] = useState<boolean>(false);
  const [trainingPackageId, setTrainingPackageId] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useState({
    cohortName: '',
    startDate: '',
    numberOfUsers: 0,
    numberOfTrainings: 0
  });
  const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState<any>(null);

  const { userId, organization } = useSelector((state: any) => state.auth);

  const { data: userData, loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: { userWithRolesByIdId: userId },
    onCompleted(data) {
      const roles = data?.userWithRolesById?.roles || [];
      const isAdmin = roles.some((role: any) => role.roleCode === "ROOT_ADMIN");
      setIsRootAdmin(isAdmin);
      const orgId = data?.userWithRolesById?.organization || null;
      setTrainingPackageId(orgId); // Assuming orgId is the training package ID, adjust if necessary
    },
    onError(err) {
      console.error("Error fetching user data:", err.message);
    }
  });

  // Query for root admin to fetch all cohorts
  const { loading: cohortsLoading } = useQuery(GET_ALL_COHORTS, {
    skip: !isRootAdmin,
    variables: {
      orgId: organization,
      search: searchParams
    },
    onCompleted(data) {
      if (data.getAllCohorts.length > 0) {
        const cohorts = data?.getAllCohorts;
        setTableData([]);
        if (cohorts.length > 0) {
          const _cohorts = cohorts.map((_singleCohort: any, index: number) => {
            return {
              key: index,
              id: _singleCohort._id,
              cohortName: _singleCohort.cohortName,
              startDate: _singleCohort.cohortStartDate,
              noOfUsers: _singleCohort.userIds,
              noOfTrainings: _singleCohort.cohortTrainings, 
            };
          });
          setTableData(_cohorts);
        }
      }
    },
    onError(err) {
      console.log("Error fetching all cohorts:", err.message);
    },
  });

  const { loading: cohortLoading } = useQuery(GET_COHORT_BY_ID, {
    skip: isRootAdmin || !trainingPackageId,
    variables: { getCohortByIdId: trainingPackageId },
    onCompleted(data) {
      console.log("Cohort data fetched:", data);
      const cohort = data?.getCohortById;
      if (cohort) {
        const _cohorts = [{
          key: 0,
          id: cohort._id,
          cohortName: cohort.cohortName,
          startDate: cohort.cohortStartDate,
          noOfUsers: cohort.userIds.length,
          noOfTrainings: cohort.cohortTrainings.length,
        }];
        setTableData(_cohorts);
      }
    },
    onError(err) {
      console.log("Error fetching cohort:", err.message);
    },
  });

  const [deleteCohort] = useMutation(DELETE_COHORT);

  const handleClickOpen = (record: any) => {
    setRecord(record);
    setOpen(true);
  };

  const handleDelete = () => {
    handleDeleteCohort(record);
    setOpen(false);
    setRecord(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteCohort = (record: any): void => {
    deleteCohort({
      variables: {
        id: record.id
      },
      onCompleted(data) {
        const updatedTableData = tableData.filter(item => item.id !== record.id);
        setTableData(updatedTableData);
        setAlert({ type: "success", message: "Cohort deleted successfully" });
        setTimeout(() => {
          setAlert({ type: 'success', message: '' });
        }, globals.timeout);
      },
      onError(error) {
        setAlert({ type: 'error', message: error.message });
        setTimeout(() => {
          setAlert({ type: 'error', message: '' });
        }, globals.timeout);
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <TableHeading 
          heading="Batch Name" 
          setSearch={(value) => setSearchParams(prev => ({ ...prev, cohortName: value }))} 
          setPage={setCurrentPage} 
        />
      ),
      dataIndex: "cohortName",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "1",
      width: "20%"
    },
    {
      title: (
        <TableHeading 
          heading="Start Date" 
          setSearch={(value) => setSearchParams(prev => ({ ...prev, startDate: value }))} 
          setPage={setCurrentPage} 
        />
      ),
      dataIndex: "startDate",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "2",
      width: "20%"
    },
    {
      title: (
        <TableHeading 
          heading="No of Users" 
          setSearch={(value) => setSearchParams(prev => ({ ...prev, numberOfUsers: value }))} 
          setPage={setCurrentPage} 
        />
      ),
      dataIndex: "noOfUsers",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "3",
      width: "20%"
    },
    {
      title: (
        <TableHeading 
          heading="No of Trainings" 
          setSearch={(value) => setSearchParams(prev => ({ ...prev, noOfTrainings: value }))} 
          setPage={setCurrentPage} 
        />
      ),
      dataIndex: "noOfTrainings",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "4",
      width: "20%"
    },
    {
      title: '',
      render: (_, record) => <Button variant="contained" sx={{ alignSelf: 'flex-end' }}><Link style={{ textDecoration: 'none', color: 'white' }} to={record.id} state={{ cohortId: record.id }}>View Details</Link></Button>,
      key: "5",
      width: "15%"
    },
    {
      title: '',
      render: (_, record) => <IconButton
                                sx={{ alignSelf: 'flex-end' }}
                                onClick={() => handleClickOpen(record)}>
                                  <DeleteIcon />
                              </IconButton>,
      key: "6",
      width: "5%"
    }
  ];

  const loading = userLoading || (isRootAdmin ? cohortsLoading : cohortLoading);

  return (
    <>
      <Stats buttonText="Create Batch" text="Total Batches" number={tableData.length} link={CREATE_COHORT_PATH} />
      <Box flexGrow={1} sx={{ pr: 3 }}>
        {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this cohort?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <DataTable
          loading={loading}
          tableData={tableData}
          totalPages={totalPages}
          showCheck={false}
          columnData={columns}
          page={(cp) => {
            setCurrentPage(Number(cp));
          }}
          scrollX="max-content"
          scrollY={450}
        />
      </Card>
    </>
  );
};

export default Cohort;