import { useQuery } from "@apollo/client";
import { GET_ALL_ORGANIZATIONS } from '../../Graphql/Queries';
import FormItemSelect from "../FormItems/FormItemSelect";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Error } from "../../Types/MessageType";
import FormItemInput from "../FormItems/FormItemInput";
import TrainingModule from "../InputRequest/TrainingModule";
import { useEffect } from "react";
import { Form } from "antd";

type createOrgProp = {
  alertMessage: Error;
  setTrainingsArray?: (val: string[]) => void;
  currentLocation: boolean;
};

const CreateCohortForm = ({ alertMessage, currentLocation, setTrainingsArray }: createOrgProp) => {
  const [form] = Form.useForm(); 
  const { data } = useQuery<any>(GET_ALL_ORGANIZATIONS, {
    variables: {
      page: 1,
      limit: 30,
    },
  });

  // Convert organizations to options format
  const organizationsOptions = data?.allOrganizations?.organizations.map((org: { name: string; _id: string }) => ({
    label: org.name,
    value: org._id,
  })) || [];

  useEffect(() => {
    // If editing an existing cohort, ensure the organization is prefilled
    if (currentLocation) {
      const orgId = form.getFieldValue("organization");
      if (orgId) {
        form.setFieldsValue({ organization: orgId });
      }
    }
  }, [currentLocation, form]);

  return (
    <>
      <Grid2 container spacing={2} paddingX={3}>
        <Grid2 xs={12} sm={6}>
          <FormItemInput label="Batch Name" name="cohortName" rules={[{ required: true, message: 'Cohort name is required' }]} type={'text'} />
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <FormItemInput label="Batch Start Date" name="cohortStartDate" type={'date'} />
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <Box>
            <TrainingModule label="Training Module" name="cohortModule" />
          </Box>
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <FormItemSelect
            label="Organization"
            name="organization"
            rules={[{ required: true, message: 'Organization is required' }]}
            value={organizationsOptions}
            setValue={(v) => form.setFieldsValue({ organization: v })}
          />
        </Grid2>
      </Grid2>
    </>
  );
};

export default CreateCohortForm;