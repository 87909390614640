import { gql } from "@apollo/client";



const SIGIN_POST = gql`
query SignIn($email: String, $password: String) {
  signIn(email: $email, password: $password) {
    email
    name
    organization
    permissions
    refreshToken
    token
    tokenTime
    userID
  }
}`;

const CREATE_ORGANIZATION = gql`
mutation CreateOrganization($org: OrganizationInput) {
  createOrganization(org: $org)
}`;

const UPDATE_ORGANIZATION = gql`
mutation Mutation($org: UpdateOrganizationInput) {
  updateOrganization(org: $org)
}`;

const CREATE_USER = gql`
mutation Mutation($userInput: UserInput) {
  createUser(userInput: $userInput)
}`;

const UPDATE_USER = gql`
mutation Mutation($userInput: UpdateUserInput) {
  updateUser(userInput: $userInput)
}`;

const CREATE_TRAINING_PACKAGE = gql`
mutation Mutation($trainingPackageInput: TrainingPackageInput) {
  createTrainingPackage(trainingPackageInput: $trainingPackageInput)
}`;

const CREATE_TRAINING = gql`
mutation CreateTraining($trainingInput: TrainingInput) {
  createTraining(trainingInput: $trainingInput)
}`;

const CREATE_COHORT = gql`
mutation Mutation($cohortInput: CohortInput) {
  createCohort(cohortInput: $cohortInput)
}`;

const CREATE_GRADE = gql`
mutation Mutation($gradingInput: GradingInput) {
  createGrading(gradingInput: $gradingInput)
}`;

const DELETE_ORGANIZATION=gql`
mutation Mutation($id: String){
  deleteOrganization(id: $id)
}`

const DELETE_COHORT=gql`
mutation Mutation($id: String){
  deleteCohortById(id: $id)
}`;

const DELETE_TRAINING=gql`
mutation Mutation($id: String){
  deleteTraining(id: $id)
}`;

const DELETE_ALLOTED_HARDWARE = gql`
mutation Mutation($id: String!) {
    deleteAllotedHardware(id: $id)
}`;

const UPDATE_TRAINING = gql`
mutation UpdateTraining($updateTrainingId: ID!, $trainingInput: TrainingInput!) {
  updateTraining(id: $updateTrainingId, trainingInput: $trainingInput) {
    _id
    name
    description
    difficultyLevel
    typeOfTraining
    trainingSteps {
      _id
      stepName
      audioText
      isActive
    }
  }
}`;


const DELETE_TRAINING_STEP = gql`
mutation DeleteTrainingStep($deleteTrainingStepId: ID!) {
  deleteTrainingStep(id: $deleteTrainingStepId) {
    message
    success
  }
}`;

export {
  SIGIN_POST,
  CREATE_USER,
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPDATE_USER,
  CREATE_TRAINING_PACKAGE,
  CREATE_TRAINING,
  CREATE_COHORT,
  CREATE_GRADE,
  DELETE_ORGANIZATION,
  DELETE_ALLOTED_HARDWARE,
  DELETE_COHORT,
  UPDATE_TRAINING,
  DELETE_TRAINING_STEP,
  DELETE_TRAINING
};