import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { Box, TextField, IconButton, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import type { Error } from '../../Types/MessageType';
import { useLocation } from 'react-router-dom';
import { DELETE_TRAINING_STEP } from '../../Graphql/Mutations';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_TRAINING_BY_ID } from '../../Graphql/Queries';

const TrainingSteps = ({ initialSteps = [], onStepsChange }: { initialSteps: any[]; onStepsChange: (steps: any[]) => void }) => {
  const [steps, setSteps] = useState(initialSteps);
  const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });
  const location = useLocation();
  const [form] = Form.useForm();

  const [getTrainingById] = useLazyQuery(GET_TRAINING_BY_ID, {
    variables: {
      trainingByIdId: location.state?.trainingId
    },
    onCompleted(data) {
      const trainingData = { ...data.trainingById };
      form.setFieldsValue(trainingData);
      const transformedSteps = trainingData.trainingSteps?.map((step: any) => ({
        _id: step._id,
        title: step.stepName, // Map stepName to title
        audioText: step.audioText,
      })) || [];

      setSteps(transformedSteps);
    }
  });

  useEffect(() => {
    setSteps(initialSteps);
  }, [initialSteps]);

  const handleAddStep = () => {
    const newSteps = [...steps, { _id: null, title: '', audioText: '' }]; // Add step with _id: null for new steps
    setSteps(newSteps);
    onStepsChange(newSteps);
  };

  const [deleteTrainingStep] = useMutation(DELETE_TRAINING_STEP, {
    onCompleted() {
      setAlert({
        type: "success",
        message: "Step deleted successfully"
      });
    },
    onError(error) {
      setAlert({
        type: "error",
        message: error.message
      });
    }
  });

  const handleDeleteStep = (stepId: string) => {
    deleteTrainingStep({ variables: { deleteTrainingStepId: stepId } }); // Correct variable name
  };

  const handleRemoveStep = (index: number) => {
    const stepToDelete = steps[index];

    if (stepToDelete._id) {
      // Call the delete API for steps with an _id
      handleDeleteStep(stepToDelete._id);
    }

    // Remove the step locally
    const newSteps = steps.filter((_, i) => i !== index);
    setSteps(newSteps);
    onStepsChange(newSteps);
  };

  const handleStepChange = (index: number, field: string, value: string) => {
    const newSteps = steps.map((step, i) =>
      i === index ? { ...step, [field]: value } : step
    );
    setSteps(newSteps);
    onStepsChange(newSteps);
  };

  return (
    <Box p={3}>
      {steps.map((step, index) => (
        <Box key={index} display="flex" alignItems="flex-start" mb={2}>
          <Typography variant="h6" style={{ marginRight: 16, marginTop: 16 }}>
            Step {index + 1}
          </Typography>
          <Box flex={1}>
            <Typography variant="subtitle1" style={{ marginBottom: 4 }}>Title</Typography>
            <TextField
             
              value={step.title}
              onChange={(e) => handleStepChange(index, 'title', e.target.value)}
              style={{ marginBottom: 8, width: '70%' }}
              helperText={step.title.length > 100 ? 'Title cannot exceed 100 characters' : ''}
              error={step.title.length > 100}
            />
            <Typography variant="subtitle1" style={{ marginBottom: 4 }}>Audio Text</Typography>
            <textarea
              rows={4}
              cols={40}
              value={step.audioText}
              onChange={(e) => handleStepChange(index, 'audioText', e.target.value)}
              style={{ marginRight: 8, padding: '10px', borderRadius: '4px', borderColor: '#c4c4c4', width: '70%' }}
            />
            {step.audioText.length > 700 && (
              <Typography color="error" variant="caption">
                Audio Text cannot exceed 700 characters
              </Typography>
            )}
          </Box>
          <IconButton onClick={() => handleRemoveStep(index)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddStep}
        >
          Add Step
        </Button>
      </Box>
    </Box>
  );
};

export default TrainingSteps;
