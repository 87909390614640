import { Form, TabsProps } from 'antd';
import { Box, Button, Stack, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import CreateTabs from "../../Components/CreateTabs";
import { useLazyQuery, useMutation } from '@apollo/client';
import type { Error } from '../../Types/MessageType';
import LoadingButton from '@mui/lab/LoadingButton';
import Message from '../../Components/Message';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateTrainingForm from '../../Components/SubPages/CreateTrainingForm';
import { SuperAdminPaths } from '../../Routes/Paths';
import { CREATE_TRAINING,UPDATE_TRAINING } from '../../Graphql/Mutations';
import { GET_TRAINING_BY_ID } from '../../Graphql/Queries';
import TrainingSteps from '../../Components/SubPages/TrainingSteps';

const CreateTraining = () => {
 const [tab, setTab] = useState<boolean>(true);
 const [steps, setSteps] = useState<any[]>([]);
 const [form] = Form.useForm();
 const location = useLocation();
 const [selectedKey, setSelectedKey] = useState<string>('1');
 const navigate = useNavigate();
 const { TRAINING } = SuperAdminPaths;
 const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });
 const [tabIndex, setTabIndex] = useState(0);

 const [getTrainingById] = useLazyQuery(GET_TRAINING_BY_ID, {
  variables: {
   trainingByIdId: location.state?.trainingId
  },
  onCompleted(data) {
   const trainingData = { ...data.trainingById };
   form.setFieldsValue(trainingData);
   const transformedSteps = trainingData.trainingSteps?.map((step:any) => ({
    _id: step._id,
    title: step.stepName, // Map stepName to title
    audioText: step.audioText,
  })) || [];

  setSteps(transformedSteps);
  }
 });

 useEffect(() => {
  if (location.state?.trainingId) {
    getTrainingById({ variables: { trainingByIdId: location.state.trainingId } });
  }
}, [location.state?.trainingId, getTrainingById]);

 const [createTrainingPackage, { loading }] = useMutation(CREATE_TRAINING, {
  onCompleted() {
   setAlert({
    type: "success",
    message: "Training created successfully"
   });
   navigate(TRAINING);
  },
  onError(error) {
   setAlert({
    type: "error",
    message: error.message
   });
  }
 });

 const [updateTrainingPackage] = useMutation(UPDATE_TRAINING, {
  onCompleted() {
    setAlert({
      type: "success",
      message: "Training updated successfully"
    });
    navigate(TRAINING);
  },
  onError(error) {
    setAlert({
      type: "error",
      message: error.message
    });
  }
});


 const items: TabsProps['items'] = [
  {
    key: '1',
    label: <h3>Training Details</h3>,
    children: <CreateTrainingForm alertMessage={alert} />
  },
  {
    key: '2',
    label: <h3>Steps</h3>,
    children: (
      <TrainingSteps
        initialSteps={steps}
        onStepsChange={setSteps}
      />
    )
  }
];


 const createTrainingHandler = async (value: any) => {
  try {
    const values = await form.validateFields();
    const trainingInput = {
      ...values,
      trainingSteps: steps.map(step => {
        const stepData: { _id?: string; stepName: any; audioText: any } = {
          stepName: step.title || step.stepName,
          audioText: step.audioText || null
        };
        if (step._id) {
          stepData._id = step._id; // Include the id only if it is not null
        }
        return stepData;
      })
    };

    // Ensure that training ID exists for update
    if (location.state?.trainingId) {
      const trainingId = location.state.trainingId;

      if (!trainingId) {
        throw new Error("Training ID is required for updating.");
      }
      // Update training
      updateTrainingPackage({
        variables: { 
          updateTrainingId: trainingId, // Pass non-nullable ID!
          trainingInput // Include the training input with steps
        }
      });
    } else {
      // Handle creation of new training if no trainingId is present
      createTrainingPackage({
        variables: {
          trainingInput // Include the training input with steps
        }
      });
    }
  } catch (error) {
    console.error("Error creating/updating training:", error);
  }
};




 const handleTabChange = (key: string) => {
  setSelectedKey(key);
  setTab(key === '2');}

 return (
  <>
   <Form
    onFinish={createTrainingHandler}
    form={form}
    layout={'vertical'}
   >
    <Box sx={{ mb: 3 }} display={'flex'} justifyContent={'flex-end'} >
     <Box flexGrow={1} sx={{ pr: 3 }}>
      {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
     </Box>
     {
      !loading ? <>
       <Stack direction={'row'} spacing={2}>
        <Button
         sx={{ px: 5, py: 1,color: 'white' ,height: '45px'}}
         variant="contained"
         type='submit'>
         {location.state?.trainingId ? 'Update' : 'Save'}</Button>
       </Stack>
      </> :
       <>
        <LoadingButton
         sx={{ px: 5, py: 1 }}
         loading
         variant="contained">
         <span>Save</span>
        </LoadingButton>
       </>
     }
    </Box>
    <CreateTabs
          items={items}
          heading={'Create Training'}
          headingStyle={{ color: 'black' }}
          keySelected={selectedKey}
          defaultKey={'1'}
           selectedTab={(isSelected: boolean) => handleTabChange(isSelected ? '2' : '1')}
        />   </Form>
  </>
 );
};

export default CreateTraining;
