import { Form, TabsProps } from 'antd';
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CreateTabs from "../../Components/CreateTabs";
import { CREATE_COHORT } from '../../Graphql/Mutations';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Error } from '../../Types/MessageType';
import globals from '../../Constants/Globals';
import LoadingButton from '@mui/lab/LoadingButton';
import Message from '../../Components/Message';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateCohortForm from '../../Components/SubPages/CreateCohortForm';
import Users from '../SuperAdmin/Users';
import Trainings from '../SuperAdmin/Trainings'; 
import { useSelector } from 'react-redux';
import { GET_COHORT_BY_ID } from '../../Graphql/Queries';
import { AdminPaths } from '../../Routes/Paths';

const { COHORT_PATH } = AdminPaths;

const CreateCohort = () => {
  const [tab, setTab] = useState<boolean>(true);
  const [selectedKey, setSelectedKey] = useState<string>('1');
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });
  const [createCohort, { loading: loadingCohort }] = useMutation(CREATE_COHORT);
  const location = useLocation();
  const navigate = useNavigate();
  
  // query to get cohort details if updating
  const [getCohortById] = useLazyQuery(GET_COHORT_BY_ID, {
    variables: {
      getCohortByIdId: location.state?.cohortId
    },
    onCompleted(data) {
      const getCohortId = data.getCohortById;
      form.setFieldsValue({
        ...getCohortId,
        organization: getCohortId.orgId,
      });
    }
  });

  useEffect(() => {
    if (!!location.state?.cohortId) {
      getCohortById();
    }
  }, [location.state?.cohortId, getCohortById]);


  // Define the tabs (Cohort Details, User Details, Training Details)
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <h3>Batch Details</h3>,
      children: <CreateCohortForm alertMessage={alert} currentLocation={!!location.state?.cohortId} />,
      disabled: true,
    },
    {
      key: '2',
      label: <h3>User Details</h3>,
      children: (
        <Form.Item name={"userIds"}>
          <Users 
            showViewDetails={false} 
            showCreateUser={false} 
            check={true} 
            tableFormItemName={"userIds"} 
            formdata={form.getFieldValue("userIds")} 
          />
        </Form.Item>
      ),
      disabled: true,
    },
    // {
    //   key: '3',
    //   label: <h3>Training Details</h3>,
    //   children: (
    //     <Form.Item name={"cohortTrainings"}>
    //       <Trainings 
    //        showViewDetails={false} 
    //        showCreateTrainings={false} 
    //         check={true} 
    //         tableFormItemName={"cohortTrainings"} 
    //         formdata={form.getFieldValue("cohortTrainings")} 
    //       />
    //     </Form.Item>
    //   ),
    //   disabled: true,
    // },
  ];

  // Submit handler
  const createCohortHandler = (value: any) => {
    let cohortFormData;
    if (!!location.state?.cohortId) {
      cohortFormData = { ...value, _id: location.state?.cohortId };
    } else {
      cohortFormData = { ...value };
    }

    createCohort({
      variables: {
        cohortInput: cohortFormData,
      },
      onCompleted() {
        setAlert({ type: "success", message: "Cohort created successfully" });
        form.resetFields();
        navigate(COHORT_PATH);
        setTimeout(() => {
          setAlert({ type: 'success', message: '' });
        }, globals.timeout);
      },
      onError(error) {
        setAlert({ type: 'error', message: error.message });
        setTimeout(() => {
          setAlert({ type: 'error', message: '' });
        }, globals.timeout);
      }
    });
  };

  // Buttons for navigation
  const showContinueButton = (
    <Button
      onClick={() => { 
        setSelectedKey('2'); 
        setTab(false); 
      }}
      sx={{ px: 5, py: 1, color: 'white', height: '45px' }}
      variant="contained"
    >
      Continue
    </Button>
  );

  const showSaveButton = (
    <Stack direction={'row'} spacing={2}>
      <Button
        sx={{ px: 5, py: 1, height: '45px', color: 'white' }}
        variant="contained"
        onClick={() => { setSelectedKey('1'); setTab(false); }} // Go back to Batch Details
      >
        Back
      </Button>
      <Button
        sx={{ px: 5, py: 1, color: 'white', height: '45px' }}
        variant="contained"
        type='submit'
      >
        {location.state?.cohortId ? 'Update' : 'Save'}
      </Button>
    </Stack>
  );
  
  return (
    <>
      <Form
        onFinish={createCohortHandler}
        form={form}
        layout={'vertical'}
        initialValues={{
          "userIds": form.getFieldValue("userIds"),
          "cohortTrainings": form.getFieldValue("cohortTrainings"), 
        }}
      >
        <Box sx={{ mb: 3 }} display={'flex'} justifyContent={'flex-end'} >
          <Box flexGrow={1} sx={{ pr: 3 }}>
            {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
          </Box>
          {selectedKey !== '2' ? showContinueButton : showSaveButton}
        </Box>
        <CreateTabs 
          items={items} 
          heading={'Create Batch'} 
          headingStyle={{ color: 'black' }} 
          keySelected={selectedKey} 
          defaultKey={'1'} 
          selectedTab={(tab) => setSelectedKey("tab")} 
        />
      </Form>
    </>
  );
};

export default CreateCohort;