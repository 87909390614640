import { useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import Card from "../Cards/Card";
import DataTable from "../Datatable";
import TableHeading from "../TableHeading";
import { GET_ORG_BY_ID } from "../../Graphql/Queries";
import { UsersType } from "../../Types/QueriesType";

interface CreateUsersProps {
  orgId: string;
}

const CreateUsers: React.FC<CreateUsersProps> = ({ orgId }) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalUsers, setTotalUsers] = useState<any>(0);
  const pageSize = 7;
  const { loading, error, data } = useQuery(GET_ORG_BY_ID, {
    variables: { organizationByIdId: orgId },
  });

  useEffect(() => {
    if (data) {
      // Access users from the organization data
      const users = data.organizationById.users || [];
      setTotalUsers(users.length);
      setTableData(users.map((user: UsersType, index: number) => ({
        key: index,
        _id: user._id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        organization: user.organization,
        roleID: user?.roles[0]?._id,
       roleCode: user?.roles[0]?.roleCode,
       roleName: user?.roles[0]?.roleName,
      })));
    }
  }, [data]); // Only run this effect when data changes

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const columns: ColumnsType<any> = [
    {
      title: <TableHeading heading="Name" setPage={setCurrentPage} />,
      dataIndex: "name",
      render: (text) => <Typography variant="body1" sx={{ textAlign: "left" }}>{text}</Typography>,
      key: "1",
    },
    {
      title: <TableHeading heading="Email" setPage={setCurrentPage} />,
      dataIndex: "email",
      render: (text) => <Typography variant="body1" sx={{ textAlign: "left" }}>{text}</Typography>,
      key: "2",
    },
    {
      title: <TableHeading heading="Phone Number" setPage={setCurrentPage} />,
      dataIndex: "phone",
      render: (text) => <Typography variant="body1" sx={{ textAlign: "left" }}>{text}</Typography>,
      key: "3",
    },
    {
      title: <TableHeading heading="Role" setPage={setCurrentPage} />,
      dataIndex: "roleName",
      render: (text) => <Typography variant="body1" sx={{ textAlign: "left" }}>{text}</Typography>,
      key: "4",
    },
  ];

  return (
    <Card>
     <DataTable
          loading={loading}
          tableData={tableData}
          totalPages={{
            limit: pageSize,
            totalPages: Math.ceil(totalUsers / pageSize),
            totalDocs: totalUsers,
            currentPage: currentPage,
            position: ['bottomRight']
          }}
          columnData={columns}
          page={(cp) => setCurrentPage(Number(cp))}
        />
    </Card>
  );
};

export default CreateUsers;
